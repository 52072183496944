import React from "react";
import { NavLink } from "react-router-dom";
import { Menu } from "semantic-ui-react";
import { connect } from "react-redux";
import { logout } from "../actions/auth";

const PageNav = ({ signedIn, roles, logout }) => (
    <>
        {signedIn && (roles.includes("USER") || roles.includes("ADMIN")) && (
            <Menu pointing secondary className="pageNav">
                <Menu.Item as={NavLink} name="dashboard" to="/" activeClassName="active" exact>
                    Home
                </Menu.Item>

                {/* <Menu.Item as={NavLink} name="subscription" to="/subscription" activeClassName="active"> */}
                {/*    Subscription */}
                {/* </Menu.Item> */}

                <Menu.Item as={NavLink} name="devices" to="/devices" activeClassName="active">
                    Devices
                </Menu.Item>

                {/* <Menu.Item as={NavLink} name="history" to="/subscription/history" activeClassName="active"> */}
                {/* History */}
                {/* </Menu.Item> */}

                {/* <Menu.Item as={NavLink} name="payment" to="/payment" activeClassName="active"> */}
                {/* Movie/TV Show Request */}
                {/* </Menu.Item> */}

                <Menu.Menu position="right">
                    <Menu.Item name="logout" onClick={logout}>
                        Logout
                    </Menu.Item>
                </Menu.Menu>
            </Menu>
        )}
    </>
);

const mapStateToProps = (state) => ({
    signedIn: state.auth.signedIn,
    roles: state.auth.roles
});

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(logout())
});

export default connect(mapStateToProps, mapDispatchToProps)(PageNav);
