const initialState = {
    submitted: false,
    message: undefined,
    form: {}
};

function usersReducer(state = initialState, action) {
    switch (action.type) {

        case "USER_ADD_REQUEST":
            return {
                ...initialState,
                user: action.user,
                submitted: true
            };

        case "USER_ADD_SUCCESS":
            return {
                ...initialState,
                success: true
            };

        case "USER_ADD_API_ERROR":
            return {
                ...state,
                submitted: false,
                message: action.message,
                form: action.form
            };

        case "USER_ADD_FORM_VALIDATION":
            return {
                ...state,
                submitted: false,
                form: {
                    ...state.form,
                    ...action.form
                }
            };

        default:
            return state;

    }
}

export default usersReducer;
