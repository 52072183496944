const initialState = {
    step: 1,
    signedIn: undefined,
    email: undefined,
    roles: [],
    submitted: false,

    // error
    message: undefined,
    form: {}
};

function authReducer(state = initialState, action) {
    switch (action.type) {

        case "SESSION_REQUEST":
            return state;

        case "SESSION_RESULTS":
            return {
                ...state,
                ...action.auth
            };

        case "LOGIN_REQUEST":
            return {
                ...initialState,
                user: action.user,
                signedIn: false,
                submitted: true
            };

        case "LOGIN_SUCCESS":
            return {
                ...state,
                step: 2,
                submitted: false
            };

        case "LOGIN_API_ERROR":
        case "LOGIN_PIN_API_ERROR":
        case "LOGIN_SEND_PIN_API_ERROR":
            return {
                ...state,
                submitted: false,
                message: action.message,
                form: action.form
            };

        case "LOGIN_PIN_REQUEST":
        case "LOGIN_SEND_PIN_REQUEST":
            return {
                ...state,
                submitted: true
            };

        case "LOGIN_PIN_SUCCESS":
            return {
                ...state,
                submitted: false
            };

        case "LOGIN_SEND_PIN_SUCCESS":
            return {
                ...state,
                step: 1,
                signedIn: true,
                submitted: false
            };

        case "LOGIN_FORM_VALIDATION":
            return {
                ...state,
                submitted: false,
                form: {
                    ...state.form,
                    ...action.form
                }
            };

        case "LOGOUT_SUCCESS":
        case "LOGOUT_ERROR":
            return {
                ...initialState,
                signedIn: false
            };

        case "LOGIN_UNLOAD_PAGE":
            return {
                ...state,
                form: {},
                message: undefined
            };

        default:
            return state;

    }
}

export default authReducer;
