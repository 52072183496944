import React from "react";
import { Header } from "semantic-ui-react";

export const PageHeader = () => (
    <Header size="huge" textAlign="center" style={{ marginBottom: "30px", color: "teal" }}>
        TV
        <i>ite</i>
    </Header>
);

export default PageHeader;
