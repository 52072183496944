import React, { Suspense } from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";

import PageWrapper from "../components/PageWrapper";

import { userAllowed } from "../helpers/utils";
import LoadingPage from "../components/LoadingPage";

const ComponentRoute = ({ signedIn, userRoles, requiredRole, userPermission, requiredPermission, publicComponent: PublicComponent, privateComponent: PrivateComponent, ...rest }) => (
    <Route
        {...rest}
        component={(props) => (
            <PageWrapper {...rest}>
                {(signedIn && userAllowed(userPermission, userRoles, requiredPermission, requiredRole)) ? (
                    <Suspense fallback={<LoadingPage />}><PrivateComponent {...props} /></Suspense>
                ) : (
                    <Suspense fallback={<LoadingPage />}><PublicComponent {...props} /></Suspense>
                )}
            </PageWrapper>
        )}
    />
);

const mapStateToProps = (state) => ({
    signedIn: !!state.auth.signedIn,
    userRoles: state.auth.roles,
    userPermission: state.auth.permission,
    auth: state.auth
});

const DualRoute = connect(mapStateToProps)(ComponentRoute);

export default DualRoute;
