const initialState = {
    submitted: false,

    // active subscription
    activeSubscription: {
        renew: false,
        payment: false,
        plan: false
    },

    // subscription forms
    subscriptions: {},

    message: undefined,
    form: {}
};

function subscriptionReducer(state = initialState, action) {
    switch (action.type) {

        case "DASHBOARD_SUCCESS":
            return {
                ...initialState,
                dashboardMessage: action.dashboard,
                paymentMessage: action.payment,
                subscriptions: state.subscriptions,
                activeSubscription: state.activeSubscription
            };

        case "GET_PLAN_REQUEST":
            return {
                ...initialState,
                subscriptions: state.subscriptions,
                submitted: true
            };

        case "GET_PLAN_SUCCESS":
        case "CANCEL_SUCCESS":
        case "SUBMIT_PAYMENT_SUCCESS":
            return {
                ...initialState,
                subscriptions: state.subscriptions,
                activeSubscription: {
                    renew: action.renew,
                    payment: action.payment,
                    plan: action.plan,
                    due: action.due
                }
            };

        case "GET_PLANS_REQUEST":
            return {
                ...initialState,
                activeSubscription: state.activeSubscription,
                submitted: true
            };

        case "GET_PLANS_SUCCESS":
            return {
                ...initialState,
                activeSubscription: state.activeSubscription,
                subscriptions: {
                    header: action.header,
                    apiKey: action.apiKey,
                    plans: action.plans
                }
            };

        case "DASHBOARD_FAILED":
        case "GET_PLAN_FAILED":
        case "CANCEL_FAILED":
        case "GET_PLANS_FAILED":
        case "SUBMIT_PAYMENT_FAILED":
            return {
                ...state,
                submitted: false,
                message: action.message,
                form: action.form
            };

        default:
            return state;

    }
}

export default subscriptionReducer;
