import React, {Suspense} from "react";
import { Route } from "react-router-dom";

import PageWrapper from "../components/PageWrapper";
import LoadingPage from "../components/LoadingPage";

const PublicRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        component={(props) => (
            <Suspense fallback={<LoadingPage />}>
                <PageWrapper {...rest}>
                    <Component {...props} />
                </PageWrapper>
            </Suspense>
        )}
    />
);

export default PublicRoute;
