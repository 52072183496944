import React from "react";
import { Header } from "semantic-ui-react";

const NotFoundPage = () => (
    <div>
        <Header as="div" dividing>
            404 - Not Found
        </Header>
        <p>The page you are looking for doesn't exist here.</p>
    </div>
);

export default NotFoundPage;
