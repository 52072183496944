import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";

import authReducer from "../reducers/authReducer";
import subscriptionReducer from "../reducers/subscriptionReducer";
import usersReducer from "../reducers/usersReducer";
import devicesReducer from "../reducers/devicesReducer";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = () => {
    const store = createStore(
        combineReducers({
            auth: authReducer,
            user: usersReducer,
            subscription: subscriptionReducer,
            devices: devicesReducer
        }),
        composeEnhancers(applyMiddleware(thunk))
    );

    return store;
};

export default configureStore;
