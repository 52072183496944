import React from "react";
import { Grid, Segment } from "semantic-ui-react";

import { ToastContainer } from "react-toastify";
import PageNav from "./PageNav";
import PageHeader from "./PageHeader";

class PageWrapper extends React.Component {

    render() {
        const { children, showHeader = true, showNav = true } = this.props;

        return (
            <Segment basic>
                <Grid centered>
                    <Grid.Row>
                        <Grid.Column stackable="true" computer="8" mobile="16">
                            {showHeader && <PageHeader {...this.props} />}
                            {showNav && <PageNav {...this.props} />}
                            {children}
                            <ToastContainer />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        );
    }

}

export default PageWrapper;
