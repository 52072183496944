import React, { lazy } from "react";
import { Router, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";

import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import DualRoute from "./DualRoute";

const LazyLoginPage = lazy(() => import("../components/LoginPage"));
const LazyLogoutPage = lazy(() => import("../components/LogoutPage"));

const LazyRegistrationPage = lazy(() => import("../components/RegistrationPage"));
const LazyDashboardPage = lazy(() => import("../components/DashboardPage"));

const LazyNotFoundPage = lazy(() => import("../components/NotFoundPage"));

// const LazySubscriptionPage = lazy(() => import("../components/SubscriptionPage"));
const LazyResetPage = lazy(() => import("../components/ResetPage"));
const LazyDevicesPage = lazy(() => import("../components/DevicesPage"));
const LazyAddCodePage = lazy(() => import("../components/AddCodePage"));

export const history = createBrowserHistory();

const ADMIN_ONLY = ["ADMIN"];
const USER_AND_ADMIN = ["ADMIN", "USER"];

const AppRouter = () => (
    <Router history={history}>
        <Switch>
            {/* <PublicRoute path="/" component={RegistrationPage} exact showHeader={true} showNav={false} /> */}
            <DualRoute
                path="/"
                publicComponent={LazyRegistrationPage}
                privateComponent={LazyDashboardPage}
                requiredRole={USER_AND_ADMIN}
                requiredPermission={[]}
                exact
            />
            {/* <PrivateRoute path="/subscription" component={LazySubscriptionPage} exact requiredRole={USER_AND_ADMIN} requiredPermission={[]} /> */}
            <PrivateRoute path="/devices" component={LazyDevicesPage} exact requiredRole={USER_AND_ADMIN} requiredPermission={[]} />
            <PrivateRoute path="/device/add" component={LazyAddCodePage} exact requiredRole={USER_AND_ADMIN} requiredPermission={[]} />
            {/* <PrivateRoute path="/optin" component={ManagePage} exact requiredRole={USER_AND_ADMIN} requiredPermission={[]} /> */}
            {/* <PrivateRoute path="/api" component={ManageApiPage} exact requiredRole={USER_AND_ADMIN} requiredPermission={[]} /> */}
            {/* <PrivateRoute path="/users" component={ManageUsersPage} exact requiredRole={USER_AND_ADMIN} requiredPermission={[]} /> */}
            {/* <PrivateRoute path="/help" component={HelpPage} exact requiredRole={USER_AND_ADMIN} requiredPermission={[]} /> */}

            {/* <PrivateRoute path="/user/add" component={AddUserPage} exact requiredRole={USER_AND_ADMIN} requiredPermission={[]} /> */}
            {/* <PrivateRoute path="/account/add" component={AddAccountPage} exact requiredRole={USER_AND_ADMIN} requiredPermission={[]} /> */}
            {/* <PrivateRoute path="/enterprise/add" component={AddEnterprisePage} exact requiredRole={SALES_ONLY} requiredPermission={[]} /> */}

            {/* <PrivateRoute path="/lines/:accountId?" component={ManagePage} requiredRole={USER_AND_ADMIN} requiredPermission={[]} /> */}
            {/* <PrivateRoute path="/user/:userId?" component={ManageUsersPage} requiredRole={USER_AND_ADMIN} requiredPermission={[]} /> */}

            <PublicRoute path="/login" component={LazyLoginPage} exact showHeader showNav={false} />
            <PublicRoute path="/logout" component={LazyLogoutPage} exact showHeader={false} showNav={false} />

            <PublicRoute path="/reset/:token?" component={LazyResetPage} exact showHeader showNav={false} />

            {/* <PrivateRoute path="/first" component={FirstLogin} exact requiredRole={USER_AND_ADMIN} requiredPermission={[]} /> */}
            {/* <PublicRoute path="/welcome/:token" component={RegistrationPage} showHeader={false} showNav={false} /> */}

            <PublicRoute component={LazyNotFoundPage} />
        </Switch>
    </Router>
);

export default AppRouter;
