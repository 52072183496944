const messageConstants = {

    "page.title": "TVite",

    "unknown.error": "Unknown error has occurred",
    "unknown.user": "Please log-in again",

    "user.exists": "Email address already exists for an existing user",

    "system.error": "System error occurred.  Please try again in a few minutes.",

    "login.auth.failed": "Authentication failed. Please check username or password and try again.",
    "Could not verify the provided CSRF token because your session was not found.": "Authentication failed. Please check username or password and try again.",
    "login.auth.other.failed": "Authentication failed",
    "login.auth.successful": "Login Successful",

    "form.pin.blank": "Enter pin",
    "form.email.blank": "Enter email address",
    "form.email.invalid": "Enter valid email address.",
    "form.email.exists": "Email address exists for another user.",
    "form.password.blank": "Enter a password",
    "form.password.not.match": "Password does not match",
    "form.password.weak": "Password too weak",


    "registration.pin.sent": "Pin successfully sent",
    "registration.pin.unknown.pin.strategy": "Unknown pin sending strategy",
    "registration.account.locked": "Account locked",
    "registration.unknown.user": "Unknown user",
    "registration.pin.max.sent": "Maximum pins sent",
    "registration.pin.mismatch": "Pin incorrect. Please try again",
    "registration.form.mismatch": "Verification failed. Please try again"
};

// getMessage
export default (key) => {
    if (messageConstants && messageConstants[key]) {
        return messageConstants[key];
    } else {
        return "Unexpected error has occurred. Please try again.";
    }
};
