const initialState = {
    submitted: false,
    message: undefined,
    form: {}
};

function devicesReducer(state = initialState, action) {
    switch (action.type) {

        case "DEVICES_REQUEST":
            return {
                ...initialState,
                submitted: true
            };

        case "DEVICES_SUCCESS":
            return {
                ...initialState,
                success: true
            };

        case "DEVICES_FAILED":
            return {
                ...state,
                submitted: false,
                message: action.message,
                form: action.form
            };

        default:
            return state;

    }
}

export default devicesReducer;
