import { existsIn } from "../helpers/utils";
import { history } from "../routers/AppRouter";
import userService from "../services/userServices";

// login(user, pass)
//  -> dispatch login request - started login
//  -> first ajax for login
//   --> success - dispatch login success
//   --> failed  - dispatch login failed

export const sessionRequest = () => ({ type: "SESSION_REQUEST" });
export const sessionResults = (auth) => ({ type: "SESSION_RESULTS", auth });

export const loginRequest = (user) => ({ type: "LOGIN_REQUEST", user });
export const loginSuccess = (roles) => ({ type: "LOGIN_SUCCESS", roles });
export const loginApiError = (message, form) => ({ type: "LOGIN_API_ERROR", message, form });

export const loginFormValidation = (form) => ({ type: "LOGIN_FORM_VALIDATION", form });

export const logoutRequest = () => ({ type: "LOGOUT_REQUEST" });
export const logoutSuccess = () => ({ type: "LOGOUT_SUCCESS" });
export const logoutError = () => ({ type: "LOGOUT_ERROR" });

export const verifyAccount = () => (dispatch, getState) => {
    dispatch(sessionRequest());

    userService.session()
        .then(
            (response) => {
                const {
                    signedIn = false, email, roles = [], permissions = []
                } = response.data;

                const state = getState();

                const auth = {};
                let changed = false;

                if (signedIn !== state.auth.signedIn) {
                    auth.signedIn = signedIn;
                    changed = true;
                }

                if (email !== state.auth.email) {
                    auth.email = email;
                    changed = true;
                }

                if (!existsIn(state.auth.roles, roles)) {
                    auth.roles = roles;
                    changed = true;
                }

                if (!existsIn(state.auth.permissions, permissions)) {
                    auth.permissions = permissions;
                    changed = true;
                }

                if (changed) {
                    dispatch(sessionResults(auth));
                }
            },
            (error) => {
                dispatch(sessionResults());
            }
        );
};

export const login = (user, pass, rememberMe) => (dispatch, getState) => new Promise(((resolve, reject) => {
    dispatch(loginRequest(user));

    userService.login(user, pass, rememberMe)
        .then(
            (response) => {
                dispatch(sessionResults(response.data));
                dispatch(loginSuccess());
                resolve(response.data);
            },
            (error) => {
                const { message, form = {} } = error.data;
                dispatch(loginApiError(message, form));
                return Promise.reject("error");
            }
        );
}));

export const logout = () => (dispatch) => {
    dispatch(logoutRequest());

    userService.logout()
        .then(
            (response) => {
                dispatch(logoutSuccess());
                history.push("/login");
            },
            (error) => {
                dispatch(logoutError());
                history.push("/login");
            }
        );
};

