import isEmail from "validator/lib/isEmail";
import isEmpty from "validator/lib/isEmpty";

import getMessageConstant from "../constants/messages";

/**
 * Some basic validation to text (fields)
 *
 * Validators:
 *
 * [{ type: "empty", message: "" }]
 * [{ type: "minLength", length: 6, message: "" }]
 * [{ type: "maxLength", length: 6, message: "" }]
 * [{ type: "isEmail", message: "" }]
 *
 * @param value - text from field
 * @param validators - array of objects used for validation
 * @returns {text|undefined} - first error found or undefined
 */
export const validate = (value, validators) => {
    const found = validators.find((validator) => {
        switch (validator.type) {

            case "empty":
                return (value == null || isEmpty(value));

            case "isEmail":
                return (!isEmail(value));

            case "minLength":
                return (value && value.length < validator.count);

            case "maxLength":
                return (value && value.length >= validator.count);

            case "passwordMatch":
                return (value[0] !== value[1]);

            case "passwordScore":
                return scorePassword(value) <= validator.score;

            default:
                return false;
        }
    });

    if (found) {
        return found;
    }

    return undefined;
};

export const hasError = (name, errors) => {
    if (errors) {
        return (errors[name] !== undefined);
    }
    return false;
};
export const getMessage = (name, errors) => {
    if (errors && errors[name]) {
        return errors[name].message;
    }
    return "";
};

const permissionsObj = Object.freeze({
    DASHBOARD_WEB: true,
    DASHBOARD_EXPORT_AND_OPT_IN: true,

    ARCHIVED_WEB: true,
    ARCHIVED_EXPORT: true,

    REPORTS_WEB: true,

    SETTINGS_WEB: true,
    SETTINGS_ADD_USER: true,
    SETTINGS_EDIT_USER: true,

    ACCOUNTS_WEB: true,
    ACCOUNTS_TOGGLE_ARCHIVING: true,
    ACCOUNTS_EDIT_OPT_IN: true,

    API_WEB: true,
    API_EDIT: true
});

export const getAllPermissions = () => ({ ...permissionsObj });
export const getAllPermissionsKeys = () => Object.keys(permissionsObj);
export const userHasAllPermissions = (userPerms) => !(getAllPermissionsKeys().find((perm) => (userPerms[perm] === undefined || userPerms[perm] === false)));


export const pageTitle = (title) => {
    if (title) {
        return `${getMessageConstant(["page.title"])} | ${title}`;
    }

    return getMessageConstant(["page.title"]);
};


export const userAllowed = (usersPermission, userRoles, requiredPermission = [], requiredRoles = []) => {
    if (requiredRoles.includes("SALES")) {
        return userRoles.includes("SALES");
    }

    if (userRoles.includes("ADMIN")) {
        return true;
    }

    if (userRoles.includes("USER")) {
        if (requiredPermission.length === 0) {
            return true;
        }

        return existsIn(usersPermission, requiredPermission, true)
    }

    return false;
};

/**
 * Make sure one or all needs array exists in has array
 *
 * @param has
 * @param needs
 * @param and - true if all in needed, false if at least one in needed
 * @returns {boolean}
 */
export const existsIn = (has = [], needs = [], and = true) => {
    needs = (typeof needs === "string") ? [needs] : needs;
    has = (typeof has === "string") ? [has] : has;

    if (and) {
        return needs.length === 0 || needs.every((need) => (has.indexOf(need) > -1));
    } else {
        return needs.length === 0 || needs.includes((need) => (has.indexOf(need) > -1));
    }
};

export const stateOptions = [
    { key: "AL", value: "AL", text: "Alabama" },
    { key: "AK", value: "AK", text: "Alaska" },
    { key: "AZ", value: "AZ", text: "Arizona" },
    { key: "AR", value: "AR", text: "Arkansas" },
    { key: "CA", value: "CA", text: "California" },
    { key: "CO", value: "CO", text: "Colorado" },
    { key: "CT", value: "CT", text: "Connecticut" },
    { key: "DE", value: "DE", text: "Delaware" },
    { key: "DC", value: "DC", text: "District Of Columbia" },
    { key: "FL", value: "FL", text: "Florida" },
    { key: "GA", value: "GA", text: "Georgia" },
    { key: "HI", value: "HI", text: "Hawaii" },
    { key: "ID", value: "ID", text: "Idaho" },
    { key: "IL", value: "IL", text: "Illinois" },
    { key: "IN", value: "IN", text: "Indiana" },
    { key: "IA", value: "IA", text: "Iowa" },
    { key: "KS", value: "KS", text: "Kansas" },
    { key: "KY", value: "KY", text: "Kentucky" },
    { key: "LA", value: "LA", text: "Louisiana" },
    { key: "ME", value: "ME", text: "Maine" },
    { key: "MD", value: "MD", text: "Maryland" },
    { key: "MA", value: "MA", text: "Massachusetts" },
    { key: "MI", value: "MI", text: "Michigan" },
    { key: "MN", value: "MN", text: "Minnesota" },
    { key: "MS", value: "MS", text: "Mississippi" },
    { key: "MO", value: "MO", text: "Missouri" },
    { key: "MT", value: "MT", text: "Montana" },
    { key: "NE", value: "NE", text: "Nebraska" },
    { key: "NV", value: "NV", text: "Nevada" },
    { key: "NH", value: "NH", text: "New Hampshire" },
    { key: "NJ", value: "NJ", text: "New Jersey" },
    { key: "NM", value: "NM", text: "New Mexico" },
    { key: "NY", value: "NY", text: "New York" },
    { key: "NC", value: "NC", text: "North Carolina" },
    { key: "ND", value: "ND", text: "North Dakota" },
    { key: "OH", value: "OH", text: "Ohio" },
    { key: "OK", value: "OK", text: "Oklahoma" },
    { key: "OR", value: "OR", text: "Oregon" },
    { key: "PA", value: "PA", text: "Pennsylvania" },
    { key: "RI", value: "RI", text: "Rhode Island" },
    { key: "SC", value: "SC", text: "South Carolina" },
    { key: "SD", value: "SD", text: "South Dakota" },
    { key: "TN", value: "TN", text: "Tennessee" },
    { key: "TX", value: "TX", text: "Texas" },
    { key: "UT", value: "UT", text: "Utah" },
    { key: "VT", value: "VT", text: "Vermont" },
    { key: "VA", value: "VA", text: "Virginia" },
    { key: "WA", value: "WA", text: "Washington" },
    { key: "WV", value: "WV", text: "West Virginia" },
    { key: "WI", value: "WI", text: "Wisconsin" },
    { key: "WY", value: "WY", text: "Wyoming" },
];

export const formatMdn = (origMdn = "") => {
    const digits = (origMdn).replace(/\D/g, "");

    const part = digits.match(/^(\d{3})(\d{3})(\d{4})$/);

    return (!part) ? origMdn : `(${part[1]}) ${part[2]}-${part[3]}`;
};


export const scorePassword = (pass) => {
    if (!pass) {
        return 0;
    }

    let score = 0;
    let variation = 0;

    // award every unique letter until 5 repetitions
    const letters = {};
    for (let i = 0; i < pass.length; i++) {
        letters[pass[i]] = (letters[pass[i]] || 0) + 1;
        score += 5.0 / letters[pass[i]];
    }

    // bonus points for mixing it up
    const variations = {
        digits: /\d/.test(pass),
        lower: /[a-z]/.test(pass),
        upper: /[A-Z]/.test(pass),
        nonWords: /\W/.test(pass)
    };

    for (const check in variations) {
        variation += variations[check] ? 1 : 0;
    }

    score += (variation - 1) * 10;

    return score;
};
