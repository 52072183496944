import React, {Suspense} from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";

import PageWrapper from "../components/PageWrapper";

import NotFoundPage from "../components/NotFoundPage";

import { userAllowed } from "../helpers/utils";
import { history } from "./AppRouter";
import LoadingPage from "../components/LoadingPage";

const ComponentRoute = ({ signedIn, userRoles, requiredRole, userPermission, requiredPermission, component: Component, ...rest }) => (
    <Route
        {...rest}
        component={(props) => (
            <PageWrapper {...rest}>
                {(signedIn) ? (
                    (userAllowed(userPermission, userRoles, requiredPermission, requiredRole)) ? (
                        <Suspense fallback={<LoadingPage />}><Component {...props} /></Suspense>
                    ) : (
                        <Suspense fallback={<LoadingPage />}><NotFoundPage {...props} /></Suspense>
                    )
                ) : (
                    history.push("/login")
                )}
            </PageWrapper>
        )}
    />
);

const mapStateToProps = (state) => ({
    signedIn: !!state.auth.signedIn,
    userRoles: state.auth.roles,
    userPermission: state.auth.permission,
    auth: state.auth
});

const PrivateRoute = connect(mapStateToProps)(ComponentRoute);

export default PrivateRoute;
